<template>
  <div>
    <!--begin::Members-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Member Add</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="ma-2 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToMembersList()">
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_member_form_1">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="member.first_name"
                    :counter="25"
                    :rules="firstNameRules"
                    label="First Name"
                    required
                    outlined
                    dense
                    class="pa-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pa-2"
                    v-model="member.last_name"
                    :counter="25"
                    :rules="lastNameRules"
                    label="Last Name"
                    required
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="member.email"
                    label="Email"
                    :rules="emailRules"
                    required
                    class="pa-2"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    class="pa-2"
                    v-model="member.role_id"
                    :items="role_list"
                    label="Role"
                    dense
                    outlined
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="6" v-if="this.$route.params.id && member.term_duration_is_expired == ''">
                  <v-text-field
                    v-model="member.term_duration"
                    
                    type="date"
                    label="Term Duration"
                    required
                    class="pa-2"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6"  v-if="subscription_type != 'Free'">
                  <v-select
                    v-model="member.committee_ids"
                    :items="committee_list"
                    label="Select Committee"
                    class="pa-2"
                    :rules="[v => !!v || 'Minimun one Committee is required']"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    outlined
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-btn
                  v-if="edit_form"
                  :disabled="!valid || disable_btn"
                  class="ml-5"
                  color="success"
                  ref="kt_store_submit"
                  @click="validateEditForm"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Update
                </v-btn>
                <v-btn
                  v-else
                  :disabled="!valid || disable_btn"
                  color="success"
                  class="ml-5"
                  ref="kt_store_submit"
                  @click="validate"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Save
                </v-btn>
              </v-row>
            </v-form>
          </div>
        </form>
      </div>
    </div>
    <!--end::Members-->

    <!--begin::Upgrade Members-->
    <div class="card card-custom gutter-b" v-if="this.$route.params.id && member.term_duration_is_expired == ''">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Upgrade / Downgrade Member</h3>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_member_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row no-gutters>
                  <v-col cols="12" sm="6">
                      <v-radio-group v-model="member2.member_type" v-if="upgrade_member_exist">
                        <v-radio
                          label="New Member"
                          value="new"
                          disabled
                        ></v-radio>
                        <v-radio
                          label="Select from existing Members"
                          value="existing"
                    
                          disabled
                        ></v-radio>
                      </v-radio-group>

                      <v-radio-group v-model="member2.member_type" v-else>
                      <v-radio
                          label="New Member"
                          value="new"
                        ></v-radio>
                        <v-radio
                          label="Select from existing Members"
                          value="existing"
                        ></v-radio>
                      </v-radio-group>
                  </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="6">
                    <v-select
                      class="pa-2"
                      v-model="member2.upgrade_downgrade_user_role_id"
                      :items="role_list"
                      label="Upgrade / Downgrade Role"
                      dense
                      outlined
                    >
                    </v-select>
                  </v-col> 
                <v-col cols="12" sm="6" v-if="member2.member_type == 'existing'">
                    <v-select
                      class="pa-2"
                      v-model="member2.upgrade_downgrade_user_id"
                      :items="member_list"
                      label="Select Member"
                      dense
                      outlined
                    >
                    </v-select>
                </v-col> 
              </v-row>
            
              <v-row no-gutters v-if="member2.member_type == 'new'">
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="member2.first_name"
                    :counter="25"
                    :rules="firstNameRules"
                    label="First Name"
                    required
                    outlined
                    dense
                    class="pa-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pa-2"
                    v-model="member2.last_name"
                    :counter="25"
                    :rules="lastNameRules"
                    label="Last Name"
                    required
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters v-if="member2.member_type == 'new'">
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="member2.email"
                    label="Email"
                    :rules="emailRules"
                    required
                    class="pa-2"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    class="pa-2"
                    v-model="member2.role_id"
                    :items="role_list"
                    label="Role"
                    dense
                    outlined
                    disabled
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row no-gutters v-if="subscription_type != 'Free' && member2.member_type == 'new'" >
                  <v-col cols="12" sm="6">
                  <v-select
                    v-model="member2.committee_ids"
                    :items="committee_list"
                    label="Select Committee"
                    class="pa-2"
                    :rules="[v => !!v || 'Minimun one Committee is required']"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    outlined
                    persistent-hint
                    disabled
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-btn
                  v-if="edit_form_2"
                  :disabled="!valid || disable_btn"
                  class="ml-5"
                  color="success"
                  ref="kt_store_submit_2"
                  @click="validateEditForm2"
                >
                  Update
                </v-btn>
                <v-btn
                  v-else
                  :disabled="!valid || disable_btn"
                  color="success"
                  class="ml-5"
                  ref="kt_store_submit_2"
                  @click="validate2"
                >
                  Save
                </v-btn>
              </v-row>
            </v-form>
          </div>
        </form>
      </div>
    </div>
    <!--end::Upgrade Members-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  STORE_MEMBER,
  UPDATE_MEMBER,
  STORE_UPGRADE_MEMBER,
  UPDATE_UPGRADE_MEMBER
} from "@/core/services/store/member.module";
import EventBus from "@/core/services/store/event-bus";
import { GET_COMPANY } from "@/core/services/store/user.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

export default {
  name: "membersCreate",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      member: {
        first_name: "",
        last_name: "",
        email: "",
        designation: "",
        role_id: "",
        committee_ids: [],
        subscription_type:"",
        term_duration:""
      },
      member2: {
        first_name: "",
        last_name: "",
        email: "",
        designation: "",
        role_id: "",
        committee_ids: [],
        subscription_type:"",
        member_type:"new",
        upgrade_downgrade_user_role_id:"",
        upgrade_downgrade_user_id:""
      },
      valid: true,
      disable_btn: false,
      committee_list: [],
      firstNameRules: [
        v => !!v || "First Name is required",
        v =>
          (v && v.length <= 25) || "First Name must be less than 25 characters"
      ],
      lastNameRules: [
        v => !!v || "Last Name is required",
        v =>
          (v && v.length <= 25) || "Last Name must be less than 25 characters"
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v =>
          /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(v) ||
          "E-mail must be valid"
      ],
      
      designationRules: [v => !!v || "Designation is required"],
      edit_form: false,
      edit_form_2: false,
      role_list: [],
      member_list: [],
      company_type: "Small Enterprise",
      subscription_type: "Free",
      upgrade_member_exist: false,
      is_new_upgrade_downgrade_user:false
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["getCompany","getSubscriptionType"])
  },
  watch: {
    getCompany: function(newVal) {
      this.company_type = newVal.company_type
        ? newVal.company_type
        : "Small Enterprise";
    },
    getSubscriptionType: function(newVal) {
      this.subscription_type = newVal ? newVal : "Free";
    }
  },
  mounted() {
    let context = this;
    if (this.$route.params.id) {
      context.edit_form = true;
      axios({
        method: "get",
        url: "member/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.member) {

            context.member = result.data.member;
            context.member.role_id = result.data.member.role;
            context.member2.role_id = result.data.member.role;

            if(result.data.member.upgrade_downgrade_user_role_id){
              context.member2.upgrade_downgrade_user_role_id = result.data.member.upgrade_downgrade_user_role_id.toString();
            }

            if(result.data.member.upgrade_downgrade_user_id){
              context.member2.upgrade_downgrade_user_id = result.data.member.upgrade_downgrade_user_id.toString();
            }  

            context.member2.committee_ids = result.data.member.committee_ids;
            
            if(context.member.is_new_upgrade_downgrade_user == "1"){
              context.member2.member_type = "new";
            }else{
              context.member2.member_type = "existing";
            }

            if(context.member.upgrade_downgrade_user_id){
              context.edit_form_2 = true;
              context.upgrade_member_exist = true;
              axios({
                method: "get",
                url: "member/" + result.data.member.upgrade_downgrade_user_id,
                baseURL: process.env.VUE_APP_ACBACK_URL
              }).then(
                  function(result1) {
                    if (result1.data.member) {
                        context.member2 = result1.data.member;

                        context.member2.role_id = result.data.member.role;

                        context.member2.upgrade_downgrade_user_role_id = result.data.member.upgrade_downgrade_user_role_id.toString();

                        context.member2.upgrade_downgrade_user_id = result.data.member.upgrade_downgrade_user_id.toString();

                        context.member2.committee_ids = result.data.member.committee_ids;
                        
                        if(context.member.is_new_upgrade_downgrade_user == "1"){
                          context.member2.member_type = "new";
                        }else{
                          context.member2.member_type = "existing";
                        }
                    }
                  }
              );    
            }
          } else {
            context.$router.go(-1); //go back to list
            Swal.fire(result.data.error, "", "error");
          }
        },
        function(error) {
          Swal.fire("Error", "Member not found.", "error");
          context.$router.push({ name: "membersList" });
        }
      );
    }

    EventBus.$on("STORE_MEMBER_BUS", function(payLoad) {
      if (payLoad) {
        context.$router.push({ name: "membersList" });
        // Swal.fire("Success", "Contact us stored successfully.", "success");
      } else {
        context.disable_btn = false;
        // Swal.fire("Error", "Error in storing contact us.", "error");
      }
    });

    EventBus.$on("GET_SUBSCRIPTION_TYPE", function(newVal) {
        context.subscription_type = newVal;
    });

    EventBus.$on("UPDATE_MEMBER_ERROR", function(newVal) {
        context.$router.push({ name: "membersList" });
    });

    if (this.getCompany && this.getCompany.length > 0) {
      this.company_type = this.getCompany.company_type;
    } else {
      this.$store.dispatch(GET_COMPANY).then(() => {
        this.company_type = this.getCompany.company_type;
      });
    }

    context.getCommitteeList();
    context.getRoleList();
    context.getMemberList();
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Member" }]);

    const member_form = KTUtil.getById("kt_member_form");
    const member_form1 = KTUtil.getById("kt_member_form_1");

    this.fv = formValidation(member_form, {});
    this.fv1 = formValidation(member_form1, {});
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    getRoleList() {
      let context = this;
      axios({
        method: "get",
        url: "committee-roles",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          result.data.committee_roles.forEach(role => {
            context.role_list.push({text: role.name, value: (role.id).toString()});
          });
        },
        function() {
          Swal.fire(
            "Error",
            "Error in fetching Committee roles list",
            "info"
          );
        }
      );
    },
    getMemberList() {
      let context = this;
      axios({
        method: "get",
        url: "members-list?member_id=" + this.$route.params.id ,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          result.data.members.forEach(member => {
            context.member_list.push({text: member.first_name+' '+member.last_name, value: (member.id).toString()});
          });
        },
        function() {
          Swal.fire(
            "Error",
            "Error in fetching Members list",
            "info"
          );
        }
      );
    },
    updateMember() {
      let context = this;
      this.member["subscription_type"] = context.subscription_type;
      this.$store.dispatch(UPDATE_MEMBER, this.member).then(() => {
        this.$router.go(-1);
        context.disable_btn = false;
        // Swal.fire("Member updated successfully", "", "success");
      });
    },
    updateMember2() {
      this.member2["subscription_type"] = this.subscription_type;
      this.member2["member_id"] = this.$route.params.id;
      this.$store.dispatch(UPDATE_UPGRADE_MEMBER, this.member2).then(() => {
        context.disable_btn = false;
      });
    },
    redirectToMembersList() {
      let context = this;
      // context.$router.go(-1);
      context.disable_btn = false;
      this.$router.push({ name: "membersList" });
    },
    submitMember() {
      let context = this;
      if (this.company_type == "Chapter Organization") {
        this.member["is_chapter_user"] = 1;
      }
      this.member["subscription_type"] = this.subscription_type;
      this.$store.dispatch(STORE_MEMBER, this.member).then(() => {
        context.disable_btn = false;
      });
      // .then(() => {
      //   // Swal.fire("Member added successfully", "", "info");
      // })
      // .then(() => {
      // });
    },
    submitMember2() {
      this.member2["subscription_type"] = this.subscription_type;
      this.member2["member_id"] = this.$route.params.id;
      this.$store.dispatch(STORE_UPGRADE_MEMBER, this.member2).then(() => {
        context.disable_btn = false;
      });
    },
    validateEditForm() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.updateMember();
      } else {
        this.snackbar = true;
      }
    },
    validateEditForm2() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.updateMember2();
      } else {
        this.snackbar = true;
      }
    },
    validate() {
      let context = this;

      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.submitMember();
      } else {
        this.snackbar = true;
      }
    },
    validate2() {
      let context = this;

      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.submitMember2();
      } else {
        this.snackbar = true;
      }
    },
    getCommitteeList() {
      let context = this;
      axios({
        method: "get",
        url: "committee",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.committee) {
            var committee_arr = result.data.committee;
            committee_arr.forEach(committee => {
              context.committee_list.push({name: committee.name, id: committee.id});
            });
          }
        },
        function() {
          context.$router.go(-1); //go back to list
          Swal.fire("Error", "Members not found!", "error");
        }
      );
    }
  }
};
</script>
